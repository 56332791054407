<template>
  <b-card title="Modification du mot de passe">
    <!-- form -->
    <validation-observer ref="changePasswordForm">
      <b-form autocomplete="off" @submit.prevent="applyChangePasswordAction()">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="actuel mot de passe"
              rules="required"
            >
              <b-form-group
                label="Actuel mot de passe"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="payload.old_password"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez votre mot de passe actuel"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="nouveau mot de passe"
              rules="required|min:8"
              vid="password"
            >
              <b-form-group
                label-for="account-new-password"
                label="Nouveau mot de passe"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="payload.new_password"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false : null"
                    name="new-password"
                    placeholder="Entrez votre nouveau mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="confirmation"
              rules="required|confirmed:password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Confirmer le mot de passe"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="payload.retypePassword"
                    :type="passwordFieldTypeRetype"
                    :state="errors.length > 0 ? false : null"
                    name="retype-password"
                    placeholder="Retapez le mot de passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isChangePasswordLoading"
              class="mt-1 mr-1"
            >
              <div v-if="isChangePasswordLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Soumettre</span>
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BSpinner,

    BForm,
    ValidationProvider,
    BFormGroup,
    ValidationObserver,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      payload: {
        old_password: "",
        new_password: "",
        retypePassword: "",
      },
      isChangePasswordLoading: false,
      required,
      email,
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions("auth", ["changePasswordAction"]),
    applyChangePasswordAction() {
      this.$refs.changePasswordForm.validate().then((success) => {
        if (success) {
          this.isChangePasswordLoading = true;
          this.changePasswordAction(this.payload)
            .then((response) => {
              this.isChangePasswordLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: "Mot de passe modifiée avec succès, Reconnectez vous",
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.isChangePasswordLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "AlertTriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>
