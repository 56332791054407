<template>
  <b-card>
    <b-tabs>
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="15"
          />
          <span class="font-medium-1 font-weight-normal">Informations</span>
        </template>

        <profile-information :account-info="accountData" />
      </b-tab>
      <!--/ general tab -->
      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="15"
          />
          <span class="font-weight-normal font-medium-1">Mot de passe</span>
        </template>

        <change-password />
      </b-tab>
      <!--/ change password tab -->
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ChangePassword from './ChangePassword.vue'
import ProfileInformation from './ProfileInformation.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ChangePassword,
    ProfileInformation,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    accountData() {
      return {
        avatar: this.getUser.profile_image,
        firstName: this.getUser.first_name,
        lastName: this.getUser.last_name,
        email: this.getUser.email,
        phoneNumber: this.getUser.phone_number,
      }
    },
  },
  created() {},
}
</script>
