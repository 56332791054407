<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            v-if="optionsLocal.avatar != ''"
            ref="previewEl"
            height="90"
            width=""
            rounded
            :src="optionsLocal.avatar"
          />
          <b-avatar
            size="104px"
            rounded
            
            v-else
            :text="
              avatarText(
                `${optionsLocal.firstName} ` + ` ${optionsLocal.lastName}`
              )
            "
          >
          </b-avatar>
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="isProfileImageLoading"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          <div v-if="isProfileImageLoading">
            <span> Chargement ... </span>
            <b-spinner small />
          </div>
          <span v-else>Changer la photo</span>
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif, .jpeg"
          :hidden="true"
          plain
          @change="applyChangeProfileImageAction"
        />
        <!--/ upload button -->

        <!--/ reset -->
        <b-card-text
          >JPG, GIF, JPEG ou PNG autorisées. Taille maximale de 800 Ko</b-card-text
        >
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Nom" label-for="account-username">
            <b-form-input
              v-model="optionsLocal.firstName"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Prénom" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.lastName"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Numéro de téléphone" label-for="account-e-mail">
            <b-form-input
              v-model="optionsLocal.phoneNumber"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75"> </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="isProfileInfoLoading"
            @click="applyChangeProfileInfoAction()"
            class="mt-2 mr-1"
          > 
            <div v-if="isProfileInfoLoading">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span v-else>Sauvegarder</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BSpinner,
    BAvatar,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    accountInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.accountInfo)),
      profileFile: null,
      avatarText,
      isProfileImageLoading: false,
      isProfileInfoLoading: false,
      url: null,
    };
  },
  methods: {
    ...mapActions("auth", [
      "changeProfileImageAction",
      "changeProfileInfoAction",
    ]),
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.accountInfo));
    },

    applyChangeProfileImageAction() { 
      this.profileFile = this.$refs.refInputEl.$el.files[0];
      this.url = URL.createObjectURL(this.profileFile);
      this.optionsLocal.avatar = this.url;
      let payload = {
        profile_image: this.profileFile,
      };
      this.isProfileImageLoading = true;
      this.changeProfileImageAction(payload)
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Succès",
                icon: "CheckIcon",
                text: "Photo de profil changée avec succès",
                variant: "success",
              },
            },
            {
              position: "top-center",
            }
          );

          this.isProfileImageLoading = false;
        })
        .catch((error) => {
          this.isProfileImageLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
       
       });
    },
    applyChangeProfileInfoAction() {
      if (
        this.getUser.last_name != this.optionsLocal.lastName ||
        this.getUser.first_name != this.optionsLocal.firstName ||
        this.getUser.phone_number != this.optionsLocal.phoneNumber
      ) {
        this.isProfileInfoLoading = true;
        let payload = {
          first_name: this.optionsLocal.firstName,
          last_name: this.optionsLocal.lastName,
          email: this.optionsLocal.email,
          phone_number: this.optionsLocal.phoneNumber,
        };
        this.changeProfileInfoAction(payload)
          .then(() => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Donnée  de profil changée avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
            this.isProfileInfoLoading = false;
          })
          .catch(() => {
            this.isProfileInfoLoading = false;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "AlertIcon",
                  text: "Erreur!, merci de réesayer",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          
          });
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Attention!",
              icon: "AlertTriangleIcon",
              text: "Aucune donnée n'est modifiée",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>